jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: false,
      imagesLoaded: true,
      contain: false,
      cellAlign: 'left',
      selectedAttraction: 0.01,
      friction: 0.15,
    }

    slider.flickity(config)
  })
});