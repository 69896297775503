jQuery(window).on('scroll', function() {
  if(150 < window.pageYOffset) {
    jQuery('header').addClass('scroll-active');

  } else {
    jQuery('header').removeClass('scroll-active');
  }
});

jQuery(window).on('load', function() {
  if(150 < window.pageYOffset) {
    jQuery('header').addClass('scroll-active');

  } else {
    jQuery('header').removeClass('scroll-active');
  }
});