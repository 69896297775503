jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');
  require('./components/header-scroll.js');
  require('./components/accordion.js');
});


// AOS Animations
AOS.init();


// Get Last decade Section For Beyond Timeline
function addActiveClassToLastSection() {
  const sections = document.querySelectorAll('.decade-section');
  const lastSectionIndex = sections.length - 1;

  sections.forEach((section, index) => {
    if (index === lastSectionIndex) {
      section.classList.add('active');

    } else {
      section.classList.remove('active');
    }
  });
}

addActiveClassToLastSection();


// Adds fixed position to nav
function setActiveNavLinks() {
  const navLinks = document.querySelectorAll('.desktop-timeline .timeline-nav a');
  const sections = document.querySelectorAll('.decade-section');

  const scrollY = window.scrollY;

  sections.forEach((section, index) => {
    const mediaQuery = window.matchMedia('(max-width: 1024px)');
    let sectionTop = section.offsetTop;

    if(mediaQuery.matches) {
      sectionTop - 72;

    } else {
      sectionTop - 80;
    }

    if (scrollY >= sectionTop) {
      navLinks.forEach(link => link.classList.remove('nav-item-active'));
      navLinks[index].classList.add('nav-item-active');
    }

    if (scrollY >= sectionTop) {
      navLinks.forEach(link => link.classList.remove('nav-item-active'));
      navLinks[index].classList.add('nav-item-active');
    }
  });
}

function mobileSetActiveNavLinks() {
  const navLinks = document.querySelectorAll('.mobile-timeline .timeline-nav a');
  const sections = document.querySelectorAll('.mobile-decade-section');

  const scrollY = window.scrollY;

  sections.forEach((section, index) => {
    let sectionTop = section.offsetTop - 72;

    if (scrollY >= sectionTop) {
      navLinks.forEach(link => link.classList.remove('nav-item-active'));
      navLinks[index].classList.add('nav-item-active');
    }

    if (scrollY >= sectionTop) {
      navLinks.forEach(link => link.classList.remove('nav-item-active'));
      navLinks[index].classList.add('nav-item-active');
    }
  });
}

window.addEventListener('scroll', setActiveNavLinks);
window.addEventListener('scroll', mobileSetActiveNavLinks);

setActiveNavLinks();
mobileSetActiveNavLinks();


// Function to add or remove the sticky class based on the scroll position
const nav = document.getElementById("stickyNav");
const navOffset = nav.offsetTop;

function updateStickyNav() {
  const scrollPosition = window.scrollY + 80;

  if (scrollPosition >= navOffset) {
    nav.classList.add("sticky");
  } else {
    nav.classList.remove("sticky");
  }
}

const navMobile = document.getElementById("stickyNav-mobile");
const navOffsetMobile = navMobile.offsetTop;

function updateStickyNavMobile() {
  const scrollPosition = window.scrollY + 72;

  if (scrollPosition >= navOffsetMobile) {
    navMobile.classList.add("sticky");
  } else {
    navMobile.classList.remove("sticky");
  }
}

window.addEventListener('scroll', updateStickyNav);
window.addEventListener('scroll', updateStickyNavMobile);

updateStickyNav();
updateStickyNavMobile();


// Art Animation
// const sand = [215, 200, 155];
// const yellow = [215, 151, 60];
// const ochre = [191, 99, 45];
// const green = [0, 149, 86];
// const blue = [7, 73, 151];

// const artAmonation = function(sections) {
//   window.addEventListener('scroll', function() {
//     // Get all the sections with SVGs

//     // Iterate over each section and apply the color fade animation to its SVG
//     sections.forEach(function(section) {
//       const svg = section.children[0].children[0];
//       const decadeColor = section.className.split(' ')[1];

//       const nextDecade = section.nextSibling;
//       const nextDecadeColor = nextDecade.className.split(' ')[1];

//       // Calculate scroll progress for each section
//       var rect = section.getBoundingClientRect();
//       var scrollPosition = window.scrollY;
//       var sectionTop = rect.top + scrollPosition;
//       var sectionHeight = rect.height;
//       var scrollProgress = Math.max(0, Math.min(1, (scrollPosition - sectionTop) / sectionHeight));

//       // Update the colors of specific elements within the SVG based on scroll progress
//       // var elements = svg.querySelectorAll('circle, rect, path');
//       // var elements = svg.querySelectorAll('path');
//       var timelineGroup = svg.querySelector('#Timeline-art---lime');
//       var paths = timelineGroup.querySelectorAll('path');

//       if(decadeColor == 'sand') {
//         startColor = sand;

//         if(nextDecadeColor == 'sand') {
//           endColor = sand;

//         } else if(nextDecadeColor == 'yellow') {
//           endColor = yellow;

//         } else if(nextDecadeColor == 'ochre') {
//           endColor = ochre;

//         } else if(nextDecadeColor == 'green') {
//           endColor = green;

//         } else if(nextDecadeColor == 'blue') {
//           endColor = blue;

//         } else {
//           endColor = startColor;
//         }

//       } else if(decadeColor == 'yellow') {
//         startColor = yellow;

//         if(nextDecadeColor == 'sand') {
//           endColor = sand;

//         } else if(nextDecadeColor == 'yellow') {
//           endColor = yellow;

//         } else if(nextDecadeColor == 'ochre') {
//           endColor = ochre;

//         } else if(nextDecadeColor == 'green') {
//           endColor = green;

//         } else if(nextDecadeColor == 'blue') {
//           endColor = blue;

//         } else {
//           endColor = startColor;
//         }

//       } else if(decadeColor == 'ochre') {
//         startColor = ochre;

//         if(nextDecadeColor == 'sand') {
//           endColor = sand;

//         } else if(nextDecadeColor == 'yellow') {
//           endColor = yellow;

//         } else if(nextDecadeColor == 'ochre') {
//           endColor = ochre;

//         } else if(nextDecadeColor == 'green') {
//           endColor = green;

//         } else if(nextDecadeColor == 'blue') {
//           endColor = blue;

//         } else {
//           endColor = startColor;
//         }

//       } else if(decadeColor == 'green') {
//         startColor = green;

//         if(nextDecadeColor == 'sand') {
//           endColor = sand;

//         } else if(nextDecadeColor == 'yellow') {
//           endColor = yellow;

//         } else if(nextDecadeColor == 'ochre') {
//           endColor = ochre;

//         } else if(nextDecadeColor == 'green') {
//           endColor = green;

//         } else if(nextDecadeColor == 'blue') {
//           endColor = blue;

//         } else {
//           endColor = startColor;
//         }

//       } else if(decadeColor == 'blue') {
//         startColor = blue;

//         if(nextDecadeColor == 'sand') {
//           endColor = sand;

//         } else if(nextDecadeColor == 'yellow') {
//           endColor = yellow;

//         } else if(nextDecadeColor == 'ochre') {
//           endColor = ochre;

//         } else if(nextDecadeColor == 'green') {
//           endColor = green;

//         } else if(nextDecadeColor == 'blue') {
//           endColor = blue;

//         } else {
//           endColor = startColor;
//         }
//       }

//       paths.forEach(function(element) {
//         var currentColor = startColor.map(function(start, index) {
//           var end = endColor[index];
//           return Math.round(start + (end - start) * scrollProgress);
//         });
//         var color = 'rgb(' + currentColor.join(',') + ')';
//         element.style.fill = color;
//       });
//     });
//   });
// }

// artAmonation(document.querySelectorAll('.decade-section'));
// artAmonation(document.querySelectorAll('.mobile-decade-section'));


// Live Radio Button
const playButton = document.getElementById('playButton');
const soundWave = document.getElementById('soundWave');
const radioPlayer = document.getElementById('radioPlayer');

function toggleRadio() {
  if (radioPlayer.paused) {
    radioPlayer.play();
		soundWave.classList.add('active');

  } else {
    radioPlayer.pause();
		soundWave.classList.remove('active');
  }
}

playButton.addEventListener('click', toggleRadio);


// Audio Popup Animation
const playButtons = document.querySelectorAll('.play-button');

playButtons.forEach(btn => {
  const bars = btn.previousElementSibling.querySelectorAll(".audiobar");
  const audio = btn.children[0];
  let interval = null;

  bars.forEach(bar =>{
    let size = Math.random();
    bar.style.transform = `scaleY(${size})`;
  });

  audio.addEventListener("ended", ()=>{
    clearInterval(interval);
  });

  btn.addEventListener('click', function() {
    btn.classList.toggle('song-active');

    if(btn.classList.contains('song-active')) {
      audio.play();
      interval = setInterval(()=>{

      bars.forEach(bar =>{
        let size = Math.random();
        let hue = Math.floor(Math.random()*360);
        bar.style.transform = `scaleY(${size})`;
      });

      }, 150);

    } else {
      clearInterval(interval);
      audio.pause();
      interval = null;

      bars.forEach(bar =>{
        bar.style.background = `white`;
      });
    }
  });

  const buttonClose = btn.parentNode.parentNode.childNodes[3];

  buttonClose.addEventListener('click', function() {
    clearInterval(interval);
    audio.pause();
    interval = null;

    bars.forEach(bar =>{
      bar.style.background = `white`;
    });
  });
});


// Play + Pause Video
jQuery(document).ready(function($) {
  jQuery('iframe[src*="https://www.youtube.com/embed/"]').addClass("youtube-iframe");
  jQuery('iframe[src*="about:blank"]').addClass("youtube-iframe");

  //Button Hover Load Popup Content
  $(function () {
    $('.parent-button').hover(function() {
      $(this).parent().find('.bio-popup').css("display", "block");
    })
  });

  jQuery('.popup-button').click(function() {
    jQuery(this).next().addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');

    radioPlayer.pause();
		soundWave.classList.remove('active');
  });

  jQuery('.popup-close').click(function() {
    jQuery(this).closest('.bio-popup').removeClass('active');
    jQuery('body, html').css('overflow-y', 'auto');

    jQuery('.song').removeClass('song-active');

    $('audio').each(function(){
      this.pause();
    });

    jQuery('.play-button').removeClass('song-active');

    jQuery('.youtube-iframe').each(function(index) {
      jQuery(this).attr('src', jQuery(this).attr('src'));
    });
  });

  jQuery('.popup-button-audio').click(function() {
    jQuery(this).closest('.buttons-container').children('.bio-popup-audio').addClass('active');
  });

  jQuery('.popup-button-content').click(function() {
    jQuery(this).closest('.buttons-container').children('.bio-popup-content').addClass('active');
  });

  jQuery('.popup-button-video').click(function() {
    jQuery(this).closest('.buttons-container').children('.bio-popup-video').addClass('active');
  });
});